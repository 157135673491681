import {UNAUTHORIZED_STATUS_CODE} from './constants';
import {GET_IZIPAY_URL, GET_USER_URL, BONUS_REGISTER} from './endpoints';
import {getByFetch} from './api';

export async function fetchIzipay() {
  const response = await getByFetch(GET_IZIPAY_URL);
  const isAuthorized = response.status !== UNAUTHORIZED_STATUS_CODE;

  if (isAuthorized) {
    const data = await response.json();
    return data;
  }

  return 0;
}

export async function fetchUserData() {
  const response = await getByFetch(GET_USER_URL);
  const isAuthorized = response.status !== UNAUTHORIZED_STATUS_CODE;

  if (isAuthorized) {
    const data = await response.json();
    return [data.name, data.email];
  }
}

export async function fetchBonusRegister() {
  const response = await getByFetch(BONUS_REGISTER);
  const data = await response.json();
  return data;
}
