import React from 'react';
import {LOGIN_GOOGLE_PAGE_URL} from '../../helpers/endpoints';
import googleIcon from '../../images/app/googleIcon.png';

const LoginPopup = (props) => {
  const {onLoginSuccess, className, showFreeQuotaText, bonusRegister} = props;


  return (
    <div onClick={onLoginSuccess} className={className}>
      <div className="login-popup__form d-flex
        flex-column align-items-center justify-content-center ta-center py-1">
        <h6>Let&apos;s Login to get Your Water</h6>
        <div
          onClick={() => window.open(LOGIN_GOOGLE_PAGE_URL, '_self')}
          className="login-popup__google-container
          d-flex p-1 align-items-center mt-1 mx-4">
          <img
            className="login-popup__google-icon"
            src={googleIcon} alt="Google Icon" />
          <p className="my-0 w-100 flex-1">Google</p>
        </div>
        <div className="d-flex align-items-center mt-1">

        </div>
        {showFreeQuotaText &&
          <div>
            <h6 style={{fontSize: '8pt'}}> You already used your free refill quota, but don`t worry,</h6>
            <h6 style={{fontSize: '8pt'}}> get <span className="text-danger">free Rp{bonusRegister} IZIPAY</span> by signing up now!</h6>
          </div>
        }
      </div>
    </div>
  );
};

export default LoginPopup;
